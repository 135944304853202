import React from "react";
import Acnavbar from "../components/Acnavbar";
import ProductDescription from "../components/ProductDescription";
import Footer from "../components/footer";
import Contact from "../components/Contact";
import Wrapper from "../components/Wrapper";
import Seo from "../shared/seo";
import "../assets/scss/index.scss";
import AboutUs from "../components/AboutUs";
import Purpose from "../components/Purpose";
import CaseStudy from "../components/CaseStudy";
import OurClients from "../components/OurClients";
import CustomerSupport from "../components/CustomerSupport";
import RecentBlogs from "../components/RecentBlogs";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function campaign() {
  const reCaptchaKey:any = process.env.RECAPTCHA_SITE_KEY
  return (
    <GoogleReCaptchaProvider
    children={true}
    reCaptchaKey={reCaptchaKey}
  >
    <Wrapper>
    <Seo
      title="Creating a Brighter Future Together"
      description="Make a difference: our mission for a brighter future. Uniting communities to address pressing issues together."
    />
    <div>
      <Acnavbar home={false} pathname={'campaign'} logo={""} logoLogic={false} />
      <ProductDescription
        topheader="CAMPAIGN"
        header="Creating a Brighter Future Together"
        headerText="Our mission is simple yet profound: to make a difference. We believe that every individual has the power to contribute to a better society, and together, we can create a brighter future for all. Our campaign aims to inspire and mobilize communities, bringing people together from all walks of life to address the pressing issues that we face today."
        showButton={true}
      />
        <AboutUs/>
        <Purpose/>
        <CaseStudy/>
        <OurClients/>
        <CustomerSupport/>
        <RecentBlogs/>     
        <Contact />
        <Footer />
    </div>
  </Wrapper>
  </GoogleReCaptchaProvider>
  );
}
