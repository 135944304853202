import React from 'react';
import campainImg from "../../static/imageCampaign.webp";
import { Container } from 'react-bootstrap';
import "../../src/assets/scss/index.scss";

export default function AboutUs() {
  const divStyle = {
    backgroundImage: `url(${campainImg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
  };

  return (
    <div style={divStyle}>
        <Container className="py-4 py-lg-5 py-md-5">
          <h2 className="color-lightGreen fnt-14 fnt-lg-18 fw-700 pb-lg-4 text-center">
                ABOUT US
          </h2>
          <div className="col-lg-6 fnt-lg-32 fw-700 lh-lg-44 text-white col-12">
            <p className="mb-4">
                Helping businesses to innovate, automate and optimize.
            </p>
            <p className="fnt-14 fnt-lg-16 fw-500 lh-24 mb-4">Softobotics is a pioneering consultancy at the forefront of transformative technology solutions. With a passion for innovation and a commitment to excellence, we specialize in providing cutting-edge strategies and services to help businesses thrive in the digital age.</p>
            <p className="fnt-14 fnt-lg-16 fw-500 lh-24">At Softobotics, we believe that technology should be an enabler, empowering organizations to achieve their goals with agility and confidence. We offer a comprehensive range of consultancy services, including digital transformation, IT strategy, process optimization, and technology implementation.</p>
          </div>
         
        </Container>
    </div>
  );
}
