import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";
import BlogCard from "../components/BlogCard";
import { graphql, useStaticQuery } from "gatsby";

export default function RecentBlogs() {
  const data = useStaticQuery(graphql`
    query pagesQuery {
      allContentfulBlogArticle {
        edges {
          node {
            id
            title
            timeToRead
            slug
            description {
              description
            }
            createdAt
            author {
              designation
              name
              profileImage {
                file {
                  contentType
                  fileName
                  url
                }
              }
            }
            previewImage {
              id
              title
              file {
                url
              }
              gatsbyImageData(layout: FIXED)
            }
            categories {
              id
              title
              contentful_id
              slug
            }
            body {
              raw
            }
          }
        }
      }

      allContentfulCategory {
        edges {
          node {
            id
            title
            slug
            description {
              description
            }
          }
        }
      }
    }
  `);

  const blogArticles = data.allContentfulBlogArticle.edges.map(
    (node: any) => node.node
  );

  const sortedArticles = blogArticles.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  const limitedArticles = sortedArticles.slice(0, 3);

  return (
    <div className="bg-navyGreen py-lg-5 py-md-5 py-4">
      <Container>
        <div className="">
          <h3 className="color-lightGreen fnt-14 fnt-lg-18 fw-700 mb-3 text-center text-uppercase">
            Blogs
          </h3>
        </div>
        <div className="">
          <h3 className="fw-500 fnt-lg-32 fnt-16 mb-3 text-center text-white">
            Recent Blogs
          </h3>
        </div>
        <Row>
          <BlogCard data={limitedArticles} />
        </Row>
      </Container>
    </div>
  );
}
